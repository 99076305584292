:root {
  --text-color: #fff;
  --input-background-color: #fff;
  --tooltip-background-color: #808080;
  --splash-screen-ring-color: #464646;
  --radio-button-background-color: #4b4b4b;
  --checkbox-radio-background-color: #333333;
  --checkbox-radio-border-color: #484848;

  //home page
  --announcements-background-color: #313131;
  --header-dropdown-shadow-color: none;
  --header-dropdown-menu-background-color: #fff;
  --notice-active-background-color: #fff;
  --notice-inactive-background-color: #414141;
  --notice-active-color: #9b9b9b;
  --notice-inactive-color: #fff;
  --arrow-right-svg: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='27.786' height='49.914' viewBox='0 0 27.786 49.914'><path d='M3641.609,791l23.543,23.543-23.543,23.543' transform='translate(-3640.195 -789.586)' fill='none' stroke='%23fff' stroke-width='4'/></svg>");
  --body-background-color: #0d0d0d;
  --body-color: #fff;
  --header-background-color: #131313;
  --header-dropdown-background-color: #fff;
  --header-dropdown-link-hover-color: #f7f4f9;
  --link-color: #fff;
  --nav-link-color: #fff;
  --wrapper-color: #131313;
  --block-background-color: #262626;

  --search-input-background-color: #fff;
  --search-border-color: #fff;
  --initials-color: #fff;
  --circle-border-color: #fff;
  --social-icon-color: #fff;

  --song-title-link-color: #fff;
  --song-background-color: #1f1f1f;
  --recently-added-block: #262626;
  --button-background-color: #fff;
  --button-color: #131313;
  --play-button-background-color: #fff;
  --play-button-color: #1f1f1f;

  --store-header-background-color: #fff;
  --store-wrapper-background-color: #f0f0f0;
  --store-last-header-background-color: #131313;
  --store-last-wrapper-background-color: #1a1a1a;
  --store-header-color: #1f1f1f;
  --store-last-header-color: #fff;
  --store-wrapper-color: #1f1f1f;
  --store-last-wrapper-color: #fff;

  --resource-background-color: #1f1f1f;
  --resource-color: #fff;

  // songs page
  --toggle-collapse-background-color: #262626;
  --collapse-active-background-color: #131313;

  --search-background-color: #fff;
  --search-placeholder-color: rgba(54, 54, 54, 0.3);

  --filter-button-color: #434343;
  --button-black-background-color: #131313;
  --button-black-color: #fff;
  --button-black-border-color: #fff;

  --sort-button-color: #fff;

  --switcher-border-color: none;

  --tab-link-color: #626262;
  --tab-link-active-color: #fff;

  --song-box-shadow-color: none;

  --song-actions-button-opacity: 0.18;
  --song-actions-button-opacity-hover: 1;

  --songs-header-buttons-background-color: #191919;

  --song-hover-brightness: 0.3;

  //song page
  --song-instruments-wrapper-color: #131313;
  --pco-icon-invert: invert(0);
  --song-container-background-color: #0d0d0d;
  --song-details-background-color: #f8f8f8;

  //artists page
  --artists-background-color: #1f1f1f;
  --artist-image-opacity: 0.3;
  --album-title-color: #fff;

  //playlists page
  --tab-underline-color: #262626;
  --genre-img-opacity: 0.85;

  //setlists page
  --setlists-background-color: inherit;
  --setlist-background-mobile-color: #000;
  --setlist-item-background-color: #2e2e2e;
  --setlist-item-color: #fff;
  --setlist-songlist-empty-background-color: #0a0a0a;

  --schedule-day-background: #2c2c2c;
  --schedule-date-background: #a8a8a8;
  --service-times-background: #2c2c2c;
  --add-button-background: #2c2c2c;

  --advanced-search-background-color: #2e2e2e;
  --search-color: rgba(255, 255, 255, 0.66);

  --setlist-header-background-color: #2e2e2e;
  --setlist-header-button-color: #fff;

  --my-role-background-color: #040404;
  --band-role-background-color: #1c1c1c;
  --collapse-button-color: #fff;

  --instrument-color: #fff;
  --instrument-hover-opacity: 0.5;

  --dropdown-background-color: #1c1c1c;
  --dropdown-item-color: #808080;
  --dropdown-hover-item-background-color: #fff;
  --dropdown-hover-item-color: #131313;

  --search-dropdown-background-color: #000;
  --search-dropdown-header-background-color: rgba(255, 255, 255, 0.22);
  --seach-dropdown-shadow-color: none;

  --add-team-form-background-color: #222;
  --add-team-form-header-background-color: #333;
  --member-search-background-color: #6a6a6a;
  --artist-row-background-color: #3c3c3c;
  --artist-info-background-color: #1c1c1c;
  --member-background-color: #383838;
  --member-border-color: #333;

  //account page
  --profile-instrument-color: #fff;
  --settings-instrument-color: #fff;
  --dark-block-background-color: #242424;
  --buttons-group-background-color: #292929;
  --buttons-group-color: #fff;
  --cancel-button-background-color: #262626;
  --cancel-button-color: #505050;
  --table-hover-background-color: #2f2f2f;
  --fieldset-background-color: #262626;
  --fieldset-legend-background-color: #393939;
  --theme-button-background-color: #1f1f1f;

  //team page
  --aside-background-color: #262626;
  --center-background-color: #131313;
  --member-background-color: #2e2e2e;
  --member-invited-background-color: #141414;
  --member-changing-background-color: #191919;
  --member-hover-background-color: #292929;
  --aside-hover-color: #fff;
  --member-body-background-color: #4b4b4b;
  --member-body-item-background-color: ##555555;
  --member-body-item-dark-background-color: #464646;

  //ivitation
  --invitation-background-color: #131313;
  --invitation-form-background-color: #333333;
  --invitation-form-shadow: none;

  //song requests
  --requests-background-color: #131313;
  --request-notation-background-color: #2e2e2e;
  --request-votes-background-color: #363636;
  --request-form-background-color: #2e2e2e;
  --request-form-input-background-color: #6a6a6a;

  //welcome
  --welcome-background-color: #0d0d0d;
  --welcome-panels-background-color: #333333;
  --welcome-light-design-background-color: #fff;
  --welcome-instrument-color: #fff;

  //signup
  --signup-form-background-color: #333333;
  --signup-input-color: #fff;
  --signup-input-placeholder-color: #525252;
  --testimonials-dot-background-color: #fff;

  //gift
  --textarea-border-hover-color: #f0f0f0;

  //modals
  --modal-overlay-background-color: #131313;
  --modal-background-color: #3a3a3a;
  --modal-devices-background-color: #242424;
  --modal-input-background-color: #333;
  --modal-input-placeholder-color: #fff;
  --modal-select_options-background-color: #292929;
  --modal-input-disabled-color: #464646;
  --modal-new-setlist-date-button-color: #2f2f2f;
  --modal-dark-block-background-color: #272727;
  --modal-darkest-block-background-color: #1d1d1d;
  --modal-groups-hover-background-color: #222222;
  --modal-info-opacity: 0.37;
  --modal-selected-row-color: #3a3a3a;
}

[data-theme='light'] {
  --text-color: #1f1f1f;
  --input-background-color: #f0f0f0;
  --tooltip-background-color: #fff;
  --splash-screen-ring-color: #e2e2e2;
  --radio-button-background-color: #eaeaea;
  --checkbox-radio-background-color: #eaeaea;
  --checkbox-radio-border-color: #a2a2a2;

  //home page
  --announcements-background-color: #fafafa;
  --header-dropdown-shadow-color: rgba(0, 0, 0, 0.16);
  --header-dropdown-menu-background-color: #fafafa;
  --notice-background-color: #eaeaea;
  --notice-active-background-color: #eaeaea;
  --notice-inactive-background-color: #fff;
  --notice-active-color: #9b9b9b;
  --notice-inactive-color: #fff;
  --arrow-right-svg: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='27.786' height='49.914' viewBox='0 0 27.786 49.914'><path d='M3641.609,791l23.543,23.543-23.543,23.543' transform='translate(-3640.195 -789.586)' fill='none' stroke='%23777' stroke-width='4'/></svg>");
  --body-background-color: #fff;
  --body-color: #434343;
  --header-background-color: #fff;
  --header-dropdown-background-color: #fff;
  --header-dropdown-link-hover-color: #f7f4f9;
  --link-color: #434343;
  --nav-link-color: #777;
  --wrapper-color: #fafafa;
  --block-background-color: #fafafa;

  --search-input-background-color: #fafafa;
  --search-border-color: #bebebe;
  --initials-color: #1f1f1f;
  --circle-border-color: #e728ea;
  --social-icon-color: #434343;

  --song-title-link-color: #2e2e2e;
  --song-background-color: #fafafa;
  --recently-added-block: #fff;
  --button-background-color: #434343;
  --button-color: #fff;
  --play-button-background-color: #a81cc2;
  --play-button-color: #fff;

  --store-header-background-color: #fafafa;
  --store-wrapper-background-color: #f3f3f3;
  --store-last-header-background-color: #e2e2e2;
  --store-last-wrapper-background-color: #dbdbdb;
  --store-header-color: #434343;
  --store-last-header-color: #434343;
  --store-wrapper-color: #131313;
  --store-last-wrapper-color: #434343;

  --resource-background-color: #fff;
  --resource-color: #434343;

  // songs page
  --toggle-collapse-background-color: #f2f2f2;
  --collapse-active-background-color: #e2e2e2;

  --search-background-color: #e8e8e8;
  --search-placeholder-color: rgba(0, 0, 0, 0.33);

  --filter-button-color: #1f1f1f;
  --button-black-background-color: #fff;
  --button-black-color: #1f1f1f;
  --button-black-border-color: #1f1f1f;

  --sort-button-color: #434343;

  --switcher-border-color: #1f1f1f;

  --tab-link-color: #eaeaea;
  --tab-link-active-color: #626262;

  --song-box-shadow-color: rgba(0, 0, 0, 0.32);

  --song-actions-button-opacity: 1;
  --song-actions-button-opacity-hover: 0.18;

  --songs-header-buttons-background-color: #fafafa;

  --song-hover-brightness: 0.5;

  //song page
  --song-instruments-wrapper-color: #eaeaea;
  --pco-icon-invert: invert(1);
  --song-container-background-color: #f7f7f7;
  --song-details-background-color: #fff;

  //artists page
  --artists-background-color: #fff;
  --artist-image-opacity: 1;
  --album-title-color: #1f1f1f;

  //playlists page
  --tab-underline-color: #eaeaea;
  --genre-img-opacity: 1;

  //setlists page
  --setlists-background-color: #fafafa;
  --setlist-background-mobile-color: #fafafa;
  --setlist-item-background-color: #eaeaea;
  --setlist-item-color: #1f1f1f;
  --setlist-songlist-empty-background-color: #fafafa;

  --schedule-day-background: #434343;
  --schedule-date-background: #f2f2f2;
  --service-times-background: #fff;
  --add-button-background: #f2f2f2;

  --advanced-search-background-color: #fafafa;
  --search-color: #1f1f1f;

  --setlist-header-background-color: #f2f2f2;
  --setlist-header-button-color: #808080;

  --my-role-background-color: #eaeaea;
  --band-role-background-color: #fafafa;
  --collapse-button-color: #777;

  --instrument-color: #1f1f1f;
  --instrument-hover-opacity: 0.1;

  --dropdown-background-color: #fff;
  --dropdown-item-color: #434343;
  --dropdown-hover-item-background-color: #808080;
  --dropdown-hover-item-color: #fff;

  --search-dropdown-background-color: #fafafa;
  --search-dropdown-header-background-color: rgba(39, 39, 39, 0.22);
  --seach-dropdown-shadow-color: rgba(0, 0, 0, 0.42);

  --add-team-form-background-color: #eaeaea;
  --add-team-form-header-background-color: #fff;
  --member-search-background-color: #fff;
  --artist-row-background-color: #fff;
  --artist-info-background-color: #f2f2f2;
  --member-background-color: #fff;
  --member-border-color: #eaeaea;

  //account page
  --profile-instrument-color: #49c7f2;
  --settings-instrument-color: #1f1f1f33;
  --dark-block-background-color: #f0f0f0;
  --buttons-group-background-color: #f0f0f0;
  --buttons-group-color: #1f1f1f;
  --cancel-button-background-color: #eaeaea;
  --cancel-button-color: #a8a8a8;
  --table-hover-background-color: #fafafa;
  --fieldset-background-color: #fff;
  --fieldset-legend-background-color: #e8e8e8;
  --theme-button-background-color: #fff;

  //team page
  --aside-background-color: #f2f2f2;
  --center-background-color: #fafafa;
  --member-background-color: #e8e8e8;
  --member-invited-background-color: #fff;
  --member-changing-background-color: #fafafa;
  --member-hover-background-color: #e2e2e2;
  --aside-hover-color: #e728ea;
  --member-body-background-color: #efefef;
  --member-body-item-background-color: #e8e8e8;
  --member-body-item-dark-background-color: #d5d5d5;

  //ivitation
  --invitation-background-color: #f7f7f7;
  --invitation-form-background-color: #fff;
  --invitation-form-shadow: 0 93px 99px rgba(0, 0, 0, 0.16);

  //song requests
  --requests-background-color: #f8f8f8;
  --request-notation-background-color: #f2f2f2;
  --request-votes-background-color: #eaeaea;
  --request-form-background-color: #eaeaea;
  --request-form-input-background-color: #f4f4f4;

  //welcome
  --welcome-background-color: #eaeaea;
  --welcome-panels-background-color: #fff;
  --welcome-light-design-background-color: #f2f2f2;
  --welcome-instrument-color: #c7c7c7;

  //signup
  --signup-form-background-color: #fafafa;
  --signup-input-color: #676767;
  --signup-input-placeholder-color: #676767;
  --testimonials-dot-background-color: #eaeaea;

  //gift
  --textarea-border-hover-color: #1f1f1f;

  //modals
  --modal-overlay-background-color: #464646;
  --modal-background-color: #fff;
  --modal-devices-background-color: #fff;
  --modal-input-background-color: #fff;
  --modal-input-placeholder-color: #a2a2a2;
  --modal-select_options-background-color: #f0f0f0;
  --modal-input-disabled-color: #a2a2a2;
  --modal-new-setlist-date-button-color: #f0f0f0;
  --modal-dark-block-background-color: #f0f0f0;
  --modal-darkest-block-background-color: #e2e2e2;
  --modal-groups-hover-background-color: #e2e2e2;
  --modal-info-opacity: 1;
  --modal-selected-row-color: #f0f0f0;
}
