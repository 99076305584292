$button-color: var(--button-color);
$button-background-color: var(--button-background-color);
$button-hover-color: $white;

button {
  background: 0;
  border: 0;
}

.button {
  background-color: $button-background-color;
  border-radius: 34px;
  font-size: $size-7;
  font-weight: $weight-bold;
  letter-spacing: 2.75px;
  text-transform: uppercase;
  border: 1px solid transparent;
  padding: 10px $gap 10px;
  color: $button-color;
  transition: background-color $duration $function;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &[disabled] {
    opacity: 0.4;
  }

  &:hover {
    background-color: #9f9f9f;
    color: $button-hover-color;
    cursor: pointer;
  }

  &.is-transparent {
    background: transparent;
    border: 2px solid var(--body-color);
    color: var(--body-color);
  }

  &.is-regular {
    border-radius: 8px;
    color: #fff;
    background-color: $grey-dark;
    padding-top: 10px;
    padding-bottom: 9px;
    padding-left: $gap / 2;
    padding-right: $gap / 2;

    @include mobile {
      border-radius: $button-border-radius;
    }
  }

  &.is-primary {
    @include worship-gradient;
    border: 0;

    &.is-large {
      font-size: 14px;
      font-weight: $weight-bold;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: #ffffff;
      padding: 16px $gap;
      border: 0;
      &.is-cyan {
        background-image: none;
        background-color: $cyan;
        padding: 7px 25px;
        @include tablet {
          padding: 15px 93px;
        }
      }
      @include tablet {
        font-size: 15px;
        letter-spacing: 3.75px;
        padding: 24px 98px;
      }

      @include mobile {
        font-size: 10px;
        padding: 10px 30px;
      }
    }
  }

  &.is-grey-disabled {
    color: $white !important;
    background-color: $grey !important;
    font-weight: $weight-bold;
    letter-spacing: 0;
    padding-left: $gap / 2;
    padding-right: $gap / 2;
  }

  &.is-white {
    background-color: $white;
    font-weight: $weight-bold;
    letter-spacing: 0;
    padding-left: $gap / 2;
    padding-right: $gap / 2;
    &:hover {
      color: $white;
      background-color: $grey;
    }
  }

  &.is-bordered {
    background-color: transparent;
    border: 2px solid var(--body-color);
    color: var(--body-color);
  }

  &.is-black {
    background-color: var(--button-black-background-color) !important;
    border-color: var(--button-black-border-color) !important;
    color: var(--button-black-color) !important;
    &:hover {
      background-color: var(--button-background-color) !important;
      border-color: var(--button-color) !important;
      color: var(--button-color) !important;
    }
    &.disabled {
      &:hover {
        background-color: $black;
        border-color: $white;
        color: $white;
      }
    }
  }

  &.is-wide {
    width: 100%;
  }

  &.is-large {
    font-size: 16px;
    font-weight: $weight-bold;
    color: #adadad;
    text-transform: none;
    background-color: transparent;
    letter-spacing: 0;
    border: 1px solid var(--body-color);

    @include mobile {
      text-transform: uppercase;
      font-size: 1rem;
      height: 40px;
    }
    @include tablet {
      font-size: 18px;
    }

    @include widescreen {
      font-size: 28px;
    }
  }

  &.is-grey {
    color: #434343;
  }

  &.is-purple,
  &.is-large,
  &.is-regular {
    &.is-purple {
      background-color: $purple;
      color: #fff;
      border-color: $purple;
      svg path {
        fill: $white;
      }
    }
  }
  &.is-rectangular {
    border-radius: 8px;
    @include desktop {
      border-radius: 19px;
    }
  }
  &.is-text {
    background-color: transparent;
    padding: 0;
    text-transform: capitalize;
    border: 0;
    font-size: 23px;
    font-weight: $weight-bold;
    letter-spacing: initial;
    border-radius: 0;

    &.is-purple {
      color: $purple;
      background-color: transparent;
    }
  }
  &.lab-button {
    color: #fff;
    font-size: 11px;
    background-image: $lab-button-gradient;
    border: none;
    border-radius: 12px;
    padding: 20px 38px;
    width: 100%;
    @include tablet {
      width: fit-content;
    }
    &.lab-button-white {
      background-image: none;
      background-color: white;
      color: #8854d1;
    }
    &.lab-button-dark {
      background-image: none;
      background-color: #313131;
    }
    &.lab-button-transparent {
      border-radius: 6px;
      background-image: none;
      background-color: transparent;
      color: white;
      border: 1px solid white;
      @include tablet {
        border-radius: 12px;
      }
    }
    &.lab-button-violet {
      width: fit-content;
      box-shadow: 0px 5px 6.5px 0px #00000012;
      background-color: #7479dc;
      background-image: none;
      text-transform: capitalize;
      font-weight: 700;
      font-size: 15px;
      padding: 9px 18px;
      border-radius: 48px;
      letter-spacing: normal;
    }
  }
  @include mobile {
    padding: 10px 15px;
    font-size: 10px;
  }
}

.buttons {
  padding-top: $gap / 2;
  margin-bottom: -$gap;
  .button {
    display: block;
    width: 100%;

    @include mobile {
      font-size: 13px;
      margin-left: 10px;
      margin-right: 10px;
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
  @include mobile {
    margin-bottom: 0;
    display: flex;
    width: calc(100% + 20px);
    position: relative;
    left: -10px;
    .creditCardForm & {
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: 0;

      .button {
        margin-top: $gap/ 2;
        &:last-child {
          margin-top: 0;
        }
      }
    }
  }
  @include tablet {
    display: flex;
    margin-top: $gap;
    .button {
      flex: 1 1 50%;
      margin-left: $gap;
      &:first-child {
        margin-left: 0;
      }
    }
    .creditCardForm & {
      margin-bottom: 0;
    }
  }
}
