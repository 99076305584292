/* TOOD: determine the fallback approach */

@font-face {
  font-family: 'AvenirNext';
  src: url('/fonts/AvenirNextLTPro-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
/*
@font-face {
  font-family: 'AvenirNext-BoldItalic';
  src: url('/fonts/AvenirNext-BoldItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
} */
/*
@font-face {
  font-family: 'AvenirNext-DemiBold';
  src: url('/fonts/AvenirNext-DemiBold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNext-DemiBoldItalic';
  src: url('/fonts/AvenirNext-DemiBoldItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNext-Heavy';
  src: url('/fonts/AvenirNext-Heavy.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNext-HeavyItalic';
  src: url('/fonts/AvenirNext-HeavyItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNext-Italic';
  src: url('/fonts/AvenirNext-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNext-MediumItalic';
  src: url('/fonts/AvenirNext-MediumItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: 'AvenirNext';
  src: url('/fonts/AvenirNextCyr-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('/fonts/AvenirNext-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/*
@font-face {
  font-family: 'AvenirNext-UltraLight';
  src: url('/fonts/AvenirNext-UltraLight.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNext-UltraLightItalic';
  src: url('/fonts/AvenirNext-UltraLightItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
 */
