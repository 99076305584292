$color1: var(--radio-button-background-color);
$color2: $purple;

.worship-radio {
  margin: 0.5rem;

  input {
    position: absolute;
    opacity: 0;

    + label {
      &:before {
        content: '';
        background: $color1;
        border-radius: 100%;
        border: 1px solid $color1;
        display: inline-block;
        width: 30px;
        height: 30px;
        position: relative;
        margin-top: 2px;
        margin-right: 1rem;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + label {
        &:before {
          background-color: $color2;
          box-shadow: 0 0 0 6px $color1;
        }
      }
    }
    &:focus {
      + label {
        &:before {
          outline: none;
          border-color: $color2;
        }
      }
    }
    &:disabled {
      + label {
        &:before {
          box-shadow: 0 0 0 6px $color1;
          border-color: darken(#4b4b4b, 25%);
          background: darken(#4b4b4b, 25%);
        }
      }
    }
    + label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}
