@import '~bulma/sass/base/_all.sass';
@import '~bulma/sass/elements/container.sass';
@import '~bulma/sass/elements/icon.sass';
@import '~bulma/sass/elements/content.sass';
@import '~bulma/sass/elements/table.sass';
@import '~bulma/sass/grid/columns.sass';
@import '~bulma/sass/form/_all.sass';
@import '~bulma/sass/layout/section.sass';
@import '~bulma/sass/helpers/all.sass';
@import '~bulma/sass/components/level.sass';

// Bulma not configurable overrides
.table thead td {
  color: var(--text-color);
}
.input {
  background-color: var(--input-background-color);
  &:active,
  &:focus {
    box-shadow: 0 0 0 0.125em var(--input-background-color);
    border-color: var(--input-background-color);
  }
}
.checkbox {
  input {
    margin-right: $gap / 4;
  }
}

.field {
  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &.is-dark {
    .input {
      background-color: transparent;
      border: 1px solid var(--body-color);
      color: var(--body-color);
      font-size: 27px;
      font-weight: $weight-extra-bold;
      &::placeholder {
        color: $grey;
        font-weight: $weight-extra-bold;
        font-size: 27px;
      }
      @include mobile {
        font-size: 20px;
        &::placeholder {
          font-size: 20px;
        }
      }
    }
  }

  .input {
    input {
      background-color: transparent;
      color: $white;
      border: 1px solid $white;
      &:hover,
      &:focus {
        border: 1px solid $white;
      }
    }
  }
}

.label {
  color: var(--text-color);
  font-weight: $weight-extra-bold;

  @include widescreen {
    font-size: 27px;
  }
}

.checkbox,
.radio {
  &:hover {
    color: $purple;
  }
}

.control {
  .select {
    width: 100%;
    &.is-large {
      select {
        line-height: 31px;
        font-weight: 300;
        font-size: 18px;

        @include widescreen {
          font-size: 27px;
          height: 62px;
        }
      }
    }
    &:not(.is-multiple):not(.is-loading)::after {
      border-color: var(--body-color);
    }
    select {
      background-color: transparent;
      color: var(--body-color);
      border: 1px solid var(--body-color);
      width: 100%;
    }
  }
}

.select:not(.is-multiple) {
  height: auto;
}

.help {
  font-weight: $weight-bold;
}

.has-addons {
  .control {
    &:not(:last-child) {
      width: 100%;
    }

    .button {
      margin-bottom: 0;
    }
  }
}

.worship-radio {
  font-size: 1.6875rem;
  font-weight: $weight-extra-bold;

  @include mobile {
    font-size: 1.25rem;
    margin: 0;
    text-align: center;
  }
  @include tablet {
    font-size: 1.25rem;
    margin: 0 !important;
  }

  @include desktop {
    font-size: 1.6875rem;
    margin: 0 !important;
  }

  input {
    & + label {
      &:before {
        @include mobile {
          width: 20px !important;
          height: 20px !important;
        }
        @include tablet {
          width: 20px !important;
          height: 20px !important;
        }
        @include desktop {
          width: 30px !important;
          height: 30px !important;
        }
      }
    }
  }
}

.has-margins {
  margin: $gap * 2 0;
}

.is-purple-text {
  font-size: inherit;
  color: $purple;
  border: 0;
  padding: 0;
  margin: 0;
  font-weight: $weight-bold;
}

.is-text-purple {
  color: $purple;
}

.is-right-aligned {
  justify-content: flex-end;
  text-align: right;
}
