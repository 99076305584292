._1-Wm_ {
  font-size: 0.8em;

  position: relative;

  width: 100%;
  padding: 0;

  -moz-user-select: none;

   -ms-user-select: none;

       user-select: none;
  -webkit-user-select: none;
  background-color: #3b3b3b;
}

._11qR5 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 30px;
}

._11qR5 > * {
  text-align: center;
}

._11qR5 h2 {
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 4.8px;
  margin-bottom: 9px;
}

._11qR5 h2 svg {
  margin-right: 25px;
}

._11qR5 p {
  font-size: 17px;
  font-weight: 700;
  margin-left: 71px;
  text-align: left;
}

._1IAFI {
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  height: 100%;
  min-height: 380px;
  position: relative;
  z-index: 2;
  overflow-x: auto;
}

._1pqSM {
  display: flex;
  justify-content: space-between;
}

._8uG_i {
  position: relative;
}

._3MVY9 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 10;
  opacity: 0.5;
}

._1m3gd {
  background-color: #343434;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
  color: #fff;
  width: 100%;
}

._1oo44 {
  padding: 16px 84px;
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  align-items: center;
}

._3c7ls {
  flex: 0 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 64px;
}

@media (max-width: 1024px) {
  ._1oo44 {
    padding: 30px 15px 15px;
  }
  ._3c7ls {
    margin-right: 20px;
  }
  ._3wjM_ {
    margin: 0 25px !important;
  }
}

._3c7ls button:last-child {
  margin-left: 28px;
}

._3OwAo {
  display: flex;
  flex: 1 0 auto;
  align-items: center;
}

._1yldA {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
}

._2h-r_ {
  font-size: 0;
}

._2h-r_,
._3wjM_,
._1AGhl {
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

._2h-r_ path,
._3wjM_ path {
  fill: #fff;
}

._3wjM_ {
  margin: 0 36px;
  position: relative;
}

._3wjM_ span {
  position: absolute;
  left: 12px;
  color: #fff !important;
  top: 11px;
  font-size: 9px;
  font-weight: 700;
}

._2h-r_:hover,
._3wjM_:hover {
  border: 0;
}

._3MzCB path {
  fill: #808080;
}

._30_8d path {
  fill: #e728ea !important;
}

._2h-r_:hover path,
._3wjM_:hover path {
  fill: #e728ea;
}

._gPG2o {
  display: flex;
  align-items: center;
}

._1AGhl:hover path {
  fill: #fff;
}

._2h-r_:active,
._2g6ef {
  transform: translateY(2px);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.18);
}

._2h-r_[disabled] {
  color: #808080;
}

._2h-r_[disabled] path, ._2h-r_[disabled] text {
  fill: #808080;
}

._263gT {
  font-size: 0.8rem;

  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
}

._7mESs {
  position: relative;
  width: 100%;
}

/* TODO: refactor all progressContainer styles to belong to fader styles */
._7mESs > div:first-child {
  border-radius: 0;
}

._7mESs [data-thumb] {
  border-radius: 0;
}

._1m3gd:hover ._2zxz8 {
  visibility: visible;
}

._2zxz8 {
  visibility: hidden;
}

._2zxz8 + div {
  border-radius: 0;
}

._MCx6k, ._3KFEM {
  position: absolute;
  font-size: 22px;
  font-weight: 400;
}

._MCx6k {
  left: 16px;
}

._3KFEM {
  right: 16px;
}

._2VO-3 {
  display: flex;
  align-items: center;
  width: 30px;
  margin-right: 18px;
}

._2VO-3 svg {
  height: 25px;
}

._3Tl82 {
  width: 170px;
}

@media only screen and (max-width: 375px) {
  ._1IAFI,
  ._1m3gd {
    max-width: 100%;
    margin-bottom: 8px;
  }
}

._j_YmS {
  z-index: 1;
  background-color: #343434;
}

._jqL0l {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column-reverse;
}

._jqL0l ._MCx6k,
._jqL0l ._3KFEM {
  top: -32px;
}

._13JBh {
    height: 100%;
    text-align: center;
    background-size: contain;
}

._13JBh:not(._2-IEx) {
  min-height: 225px;
}

._10BND {
    position: relative;

    height: calc(100% - 60px);
    margin-top: 35px;
}

._10BND:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 8px;
    margin-left: -4px;

    content: '';

    border-radius: 8px;

    background: #555;
    box-shadow: inset 0 0 10px #000;
}

._257Io {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 50%;

    width: 30px;
    height: 60px;
    margin-left: -15px;

    cursor: s-resize;
    transform: translateY(50%);

    border-radius: 2px;
    background: linear-gradient(to bottom, #999 0%, #eee 11%, #ccc 48.33%, #333 50%, #ccc 51.66%, #eee 88.33%, #999 100%);
    box-shadow: 0 4px 5px rgba(0,0,0,.3), 0 2px 1px rgba(0,0,0,.5);

    touch-action: none;
}


._2-IEx ._10BND {
    margin: 0;
    padding: 0 15px;

    -webkit-user-select: none;

       -moz-user-select: none;

        -ms-user-select: none;

            user-select: none;
}

._2-IEx ._257Io {
    top: -3px;
    left: auto;

    width: 30px;
    height: 15px;
    margin: 0;

    cursor: e-resize;
    transform: translateX(-50%);

    background: linear-gradient(to right, #999 0%, #eee 11%, #ccc 48.33%, #333 50%, #ccc 51.66%, #eee 88.33%, #999 100%);
}

._2-IEx ._10BND:after {
    left: 0;

    width: 100%;
    height: 10px;
    margin: 0;
}

._2U4pe {
    transition: all 250ms ease-in-out;
}

._1iCbM {
  min-width: 170px;
  max-width: 100%;
  height: 11px;
  border-radius: 8px;
}

._1iCbM ._10BND {
  box-shadow: none;
  background-color: #2e2e2e;
  height: 11px;
  border-radius: 8px;
  padding: 0;
}

._1iCbM ._257Io {
  width: 23px;
  height: 23px;
  background: #ffffff;
  border-radius: 23px;
  position: absolute;
  top: -50%;
  margin-top: -1px;
  z-index: 2;
}

._1iCbM ._10BND:after {
  display: none;
}

._2I5Nz {
  box-shadow: none;
  background-color: #ba14bd;
  height: 11px;
  border-radius: 8px;
}

._21SxR ._1iCbM[data-thumb] {
  visibility: visible;
}

._30z-Q {
  position: relative;
  box-shadow: -19px 0 25px rgba(0, 0, 0, 0.37);
  z-index: 10;
}

._O66Pp {
    position: absolute;
    left: 50%;
    transform: rotate(180deg) translateX(50%);
    bottom: 70px;
    z-index: 1;
    height: 178px;
}

._1AW5d {
  padding: 57px 0 10px;
}

._1AW5d svg {
  width: 25px;
  height: 25px;
}

._3BUYU {
  display: flex;
  flex-flow: column;
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  min-height: 380px;
  text-align: center;
  position: relative;

  background: #808080;
  background-size: contain;
}

._1Cqcs {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 16px;
  background-color: #454545;
}

._1Cqcs button {
  flex: 0 0 50%;
}

._3BUYU:first-child {
  margin-left: auto;
}

._3BUYU:not(:last-child) {
  margin-right: 8px;
}

._1L-2M {
  font-size: 1em;
  font-weight: 700;
  flex: 1 1 auto;

  color: #fff;
  overflow: hidden;

  padding: 7px 8px;

  cursor: pointer;
  transition: all 80ms ease-in-out;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;

  border: none;
  outline: none;
  background-color: #454545;
  position: relative;
}

._3Ixgn {
  width: 1px;
  height: 20px;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
  visibility: visible;
}

._YgY92 {
  visibility: hidden;
}

._1L-2M:hover {
  transform: translateY(1px);
}

._2qitC {
  background-color: #e728ea;
}

._3gNTh._2qitC {
  background-color: #49c7f2;
}

._YK7RV,
._349aI,
._dsFRJ {
  position: relative;

  display: flex;

  margin: 8px 8px 16px;
}

._3E8cA {
  font-size: 0.8rem;

  margin-top: 10px;
}

._2CYti {
  width: 60%;
  height: 26px;
  margin: 0 auto 10px;
}

._3awcq {
  font-weight: bold;

  display: inline-block;
  overflow: hidden;

  width: 100%;

  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;

  color: #555;
  text-shadow: 1px 1px 0 #eee;
}

._HJcKF {
  line-height: 44px;
  min-height: 44px;
  color: #fff;
  font-weight: 700;
  font-size: 10px;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 8px;
}

._3W2BP {
  white-space: nowrap;
}

._35ZSI {
    position: absolute;
    left: 50%;
    margin-left: -3px;
    bottom: 70px;
    width: 6px;
    z-index: 1;
    overflow: hidden;
    will-change: height;
}

._35ZSI::after {
  content: '';
  height: 176px;
  bottom: 0;
  width: 6px;
  position: absolute;
  border-radius: 8px;
  left: 0;
  background: linear-gradient(0deg, rgba(40,234,182,1) 0%, rgba(255,231,0,1) 60%, rgba(255,0,0,1) 100%);
}

._3Zmt3 {
    display: flex;
    flex-flow: column;

    cursor: pointer;
}

._DvLW9 {
    font-weight: 700;

    display: flex;
    align-items: center;

    margin-bottom: 8px;

    text-transform: capitalize;

    color: #555;
    text-shadow: 1px 1px 0 #eee;
}

._2Z_u6 {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    flex-shrink: 0;

    border-radius: 50%;
    background: #fff;
    box-shadow: inset 0 0 0 2px #232323;
}

._144N9 {
    line-height: .8;

    flex-grow: 1;

    text-align: right;

    border-bottom: 1px dotted rgba(0,0,0,.2);
}

._2rxfh {
    display: flex;
    align-items: center;
    justify-content: center;
}

._2rxfh:after {
    width: 8px;
    height: 8px;

    content: '';

    border-radius: 50%;
    background: #232323;
}

._2JOAY {
    position: relative;

    display: flex;

    margin: 8px 8px 16px;
}

._iq48H {
    flex-grow: 1;

    width: 40%;
    margin-right: 16px;
}

._16rzN {
    font-weight: bold;

    display: inline-block;
    overflow: hidden;

    width: 35%;
    margin-right: 5%;
    margin-left: 8px;

    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;

    color: #555;
    text-shadow: 1px 1px 0 #eee;
}

._1cYTr {
    overflow: hidden;

    width: 100%;
    margin: 8px auto 0;

    border-radius: 4px;
    background-color: #808080;
    background-size: contain;
    box-shadow: 0 0 3px #000;
}

._ooyU- {
    font-weight: bold;

    margin-bottom: 16px;
    padding: 8px 16px;

    color: rgba(255,255,255,.87);
    border-radius: 4px 4px 0 0;
    background: #1b5e20;
}

._1cYTr:not(:first-child) ._ooyU- {
    background: #827717;
}

._35aI8 {
  display: flex;
  position: relative;
  align-self: center;
  width: 50px;
  height: 50px
}

._35aI8._1EijJ {
  border-radius: 50%;
  background-color: #fff;
  margin: 0;
}
._35aI8._2jAgz {
  border-radius: 50%;
}

._35aI8._2jAgz ._Q3Pti {
  position: absolute;
  width: 5px;
  height: 33px;
  bottom: 2%;
  left: 50%;
  transform: translateX(-50%) translateY(7px);
  will-change: transform;
  transition: transform 0.2s ease-in-out;
  border-radius: 8px;
  background-color: #e728ea;
}
