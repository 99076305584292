@import '../../sass/reuse';

.modal {
  z-index: 100;
  position: relative;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  padding: 67px 90px 75px;
  max-width: 65vw;

  .confimationModal {
    font-size: $size-4;
    padding: $gap;

    & + .buttons {
      margin: 0;
    }

    @include mobile {
      flex-direction: column;
      button {
        height: 60px;
        margin-left: 0;
        margin-right: 0;
        &:not(:last-child) {
          margin-bottom: 20px !important;
        }
      }
    }
  }

  @include mobile {
    max-width: 100vw;
    padding: 0;
  }

  @include tablet-only {
    max-width: 90vw;
    padding: 0 0 75px;
  }

  @include desktop {
    max-width: 70vw;
    padding: 0;
    figure {
      display: flex;
      align-items: center;
      margin-bottom: 58px;
      img {
        width: 205px;
        height: 205px;
        object-fit: cover;
        margin-right: 58px;
      }
    }
  }

  @include widescreen {
    max-width: 65vw;
  }

  @include fullhd {
    max-width: 55vw;
  }

  @media (min-width: 2330px) {
    max-width: 1200px;
  }

  .select {
    width: 100%;
  }

  [class*='loader'] {
    height: 70vh;
  }
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: var(--modal-overlay-background-color);
  opacity: 0.9;
}

.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  h1 {
    color: $white;
    font-size: 36px;
    font-weight: $weight-light;
    margin-bottom: 22px;
    margin-left: 32px;
    text-transform: capitalize;

    @include mobile {
      font-size: 24px;
      margin-bottom: 12px;
    }

    @include desktop {
      font-size: 40px;
      margin-left: 40px;
      margin-bottom: 25px;
    }

    @include fullhd {
      font-size: 56px;
      margin-bottom: 35px;
      margin-left: 50px;
    }
  }
}

.modalContainer {
  background-color: var(--modal-background-color);
  border-radius: $radius;
  @include mobile {
    border-radius: 20px;
  }
  box-shadow: 0 93px 99px rgba(0, 0, 0, 0.16);
}

.modalClose {
  border: none;
  font-size: $size-3;
  height: $header-height;
  line-height: $header-height;
  border: 0;
  background: transparent;
  color: $white;
  padding: 0 $gap;

  @include tablet {
    transform: translateY(-18px);
    height: $header-height-tablet;
    line-height: $header-height-tablet;
  }

  @include desktop {
    transform: translateY(-25px);
  }

  @include fullhd {
    transform: translateY(-28px);
  }

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }

  &Icon {
    font-size: 21px;
    vertical-align: middle;

    @include tablet {
      font-size: 27px;
    }

    @include desktop {
      font-size: 33px;
    }

    @include fullhd {
      font-size: 43px;
    }

    &:focus {
      outline: none;
    }
  }
}
