@import './reuse';

$rhap_theme-color: $white !default;
$rhap_background-color: #1c1c1c !default;
$rhap_bar-color: #2e2e2e !default;
$rhap_time-color: $white !default;
$rhap_font-family: inherit !default;

.rhap_container {
  display: flex;
  flex-direction: column;
  line-height: 1;
  font-family: $rhap_font-family;
  width: 100%;
  position: fixed;
  bottom: 0px;
  perspective-origin: 80% 100%;
  z-index: 20;
  &:focus:not(:focus-visible) {
    outline: 0;
  }
}

.rhap_header {
  margin-bottom: 10px;
}

.rhap_footer {
  margin-top: 5px;
}

.rhap_main {
  padding: $gap / 2 $gap / 2;
  background-color: $rhap_background-color;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
}

.rhap_stacked {
  .rhap_controls-section {
    margin-top: 8px;
  }
}

.rhap_horizontal {
  flex-direction: row;

  .rhap_controls-section {
    margin-left: 8px;
  }
}

.rhap_horizontal-reverse {
  flex-direction: row-reverse;

  .rhap_controls-section {
    margin-right: 8px;
  }
}

.rhap_stacked-reverse {
  flex-direction: column-reverse;

  .rhap_controls-section {
    margin-bottom: 8px;
  }
}

$progress-bar-height: 8px;

.rhap_progress-container {
  display: flex;
  align-items: center;
  height: $progress-bar-height;
  flex: 1 0 auto;
  align-self: center;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  &:focus:not(:focus-visible) {
    outline: 0;
  }
}

.rhap_time {
  color: $rhap_time-color;
  user-select: none;
  position: absolute;
  left: $gap;
  top: $gap / 2;
}

.rhap_total-time {
  left: initial;
  right: $gap;
}

.rhap_progress-bar {
  box-sizing: border-box;
  position: relative;
  z-index: 0;
  width: 100%;
  height: $progress-bar-height;
  border-radius: 2px;
}

.rhap_progress-filled {
  height: 100%;
  position: absolute;
  z-index: 2;
  background-color: $purple;
  border-radius: 2px;
}

.rhap_progress-bar-show-download {
  background-color: rgba($rhap_bar-color, 0.5);
}

.rhap_download-progress {
  height: 100%;
  position: absolute;
  z-index: 1;
  background-color: $rhap_background-color;
  border-radius: 2px;
}

.rhap_controls-section {
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  align-items: center;
}

.rhap_additional-controls {
  display: flex;
  flex: 1 0 auto;
  align-items: center;

  button {
    margin-right: $gap / 2;
    margin-bottom: 0;
  }
}

.rhap_repeat-button {
  color: $rhap_theme-color;
}

.rhap_main-controls {
  flex: 0 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: $gap * 3;
  margin-right: $gap * 2;
}

.rhap_main-controls-button {
  margin-right: $gap;
  &:last-child {
    margin-right: 0;
  }
}

.rhap_button-clear {
  margin-right: $gap / 4;
  color: $rhap_theme-color;
  font-size: 24px;
}

.rhap_play-pause-button {
  font-size: 32px;
}

.rhap_volume-controls {
  display: flex;
  flex: 1 0 auto;
  align-items: center;
}

.rhap_volume-button {
  color: $rhap_theme-color;
  margin-right: 8px;
}

.rhap_volume-container {
  display: flex;
  align-items: center;
  flex: 0 1 120px;
  user-select: none;
  margin-right: $gap;
}

.rhap_volume-bar-area {
  display: flex;
  align-items: center;
  width: 100%;
  height: 14px;
  cursor: pointer;

  &:focus:not(:focus-visible) {
    outline: 0;
  }
}

.rhap_volume-bar {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 4px;
  background: $rhap_bar-color;
  border-radius: 2px;
}

.rhap_volume-indicator {
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  height: 12px;
  margin-left: -8px;
  left: 0;
  top: -4px;
  background: $rhap_theme-color;
  opacity: 0.9;
  border-radius: 50px;
  box-shadow: rgba($rhap_theme-color, 0.5) 0 0 3px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
}

.rphap-stem-name {
  font-size: 22px;
  font-weight: $weight-bold;
  max-width: 320px;
  width: 100%;
  text-overflow: ellipsis;
  text-transform: uppercase;
  overflow: hidden;
}
