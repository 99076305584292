@import './reuse';

html {
  height: 100%;
  scroll-behavior: smooth;
}
body {
  min-height: 100%;
  background-color: var(--body-background-color);
  & > * {
    user-select: none;
  }
}

.container {
  position: static;
  max-width: 100%;
}

.reactivateBlock {
  text-align: center;
  padding: 5px 0;
  font-weight: 600;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  cursor: pointer;
  @include worship-gradient;
}

.section {
  @include mobile {
    padding: 24px;
  }
}

@include mobile {
  .blockScroll {
    height: 100vh;
    overflow: hidden;
    touch-action: none;
  }
}

.theme-color-wrapper {
  background-color: var(--wrapper-color);
  &.columns.is-gapless {
    margin-bottom: 0;
  }
}

.is-flex-wrap {
  flex-wrap: wrap;
}

.is-flex-column {
  display: flex;
  flex-direction: column;
}

.is-purple-link {
  color: $purple;
  &:hover {
    color: var(--body-color);
  }
}

.is-hidden {
  display: none;
}

.is-hidden-touch {
  @include touch {
    display: none;
  }
}

input {
  &::placeholder {
    opacity: 0.5;
    color: $black;
    font-size: $size-7;
    font-weight: $weight-medium;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
}

.input {
  color: $body-background-color;
}

.is-square {
  width: 100%;
  padding-bottom: 100%;
  position: relative;

  a,
  div {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 100%;
    & > * {
      width: 100%;
      height: 100%;
    }
  }

  img {
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.control {
  @include mobile {
    &.has-icons-left {
      .input {
        padding-left: $gap * 0.65625;
      }
      .icon {
        display: none;
      }
    }
    &.has-icons-right {
      .input {
        padding-right: $gap * 0.65625;
      }
      .icon {
        display: none;
      }
    }
  }
}

.field.has-addons {
  @include mobile {
    display: block;

    .control {
      &:first-child:not(:only-child) {
        .button,
        .input,
        .select select {
          border-radius: 8px;
          margin-bottom: $gap;
        }
      }
      &:last-child:not(:only-child) {
        .button,
        .input,
        .select select {
          border-radius: 8px;
          width: 100%;
          margin-bottom: $gap;
          padding-bottom: 0;
          height: 60px;
          text-align: center;
          color: #fff;
          padding: 0 65px;
          border: 0;
          font-size: 0.7rem;
          line-height: 60px;
          letter-spacing: 3px;
          display: inline-block;
          text-transform: uppercase;
        }
      }
    }
  }
  @include tablet {
    .control {
      &:first-child:not(:only-child) {
        .button,
        .input,
        .select select {
          border-radius: 0;
          margin-bottom: 0;
        }
      }
      &:last-child:not(:only-child) {
        .button,
        .input,
        .select select {
          line-height: 3.8rem;
          display: flex;
          height: 3.8rem;
          align-items: center;
        }
      }
    }
  }
}

.is-rectangle {
  width: 100%;
  padding-bottom: 75%;
  position: relative;

  img {
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.is-circle {
  border-radius: 50%;
}

.has-margins {
  * + {
    blockquote,
    figure,
    table {
      margin-bottom: rem($gap);
      margin-top: rem($gap);
    }
  }

  dd,
  br,
  th,
  td,
  figcaption {
    margin-top: 0; // Remove top margins for select flow elements
  }

  * + h1,
  * + h2,
  * + h3,
  * + h4,
  * + h5,
  * + h6 {
    margin-top: rem($gap);
  }
}

.hideText {
  .chord-text {
    display: none;
  }
}

.hideAll {
  .chord {
    display: none;
  }
}

.chord {
  font-weight: $weight-bold;
  color: $black;
  font-size: 2.2vw;
  @include mobile {
    font-size: 4vw;
  }
  @media screen and (min-width: 520px) and (max-width: 767px) {
    font-size: 2.8vw !important;
  }

  @media screen and (min-width: 1216px) and (max-width: 1416px) {
    font-size: 1.25vw !important;
  }

  @include widescreen {
    font-size: 20px;
  }

  @include fullhd {
    font-size: 22px;
  }
  @media screen and (min-width: 1530px) and (max-width: 1640px) {
    font-size: 1.3vw !important;
  }
}
.superscripted {
  position: relative;
  bottom: 0.25em;
  font-size: 0.75em;
  vertical-align: top;
}

.react-datepicker__triangle {
  left: 120px !important;
}

.chord-text {
  text-align: center !important;
  font-size: 2.2vw;
  @include mobile {
    font-size: 4vw;
  }
  @media screen and (min-width: 520px) and (max-width: 767px) {
    font-size: 2.8vw !important;
  }

  @media screen and (min-width: 1216px) and (max-width: 1416px) {
    font-size: 1.25vw !important;
  }

  @include widescreen {
    font-size: 20px;
  }

  @include fullhd {
    font-size: 22px;
  }
  @media screen and (min-width: 1530px) and (max-width: 1640px) {
    font-size: 1.3vw !important;
  }
}

.full-page-container {
  padding: $gap;
}

.dragging-container {
  pointer-events: auto !important;
  cursor: move;
}

.dragging-container div {
  pointer-events: none !important;
}

@media print {
  .is-hidden-print,
  .is-hidden-print * {
    display: none !important;
  }
}

.has-text-centered {
  @include tablet {
    text-align: start !important;
  }
  @include desktop {
    text-align: center !important;
  }
}

.header-container {
  padding: 0 2em;
  background: var(--header-background-color);
  position: relative;
  z-index: 6;
  height: $header-height;

  @include tablet {
    height: $header-height-tablet;
  }

  @include desktop {
    height: $header-height-desktop;
  }

  @include fullhd {
    padding: 0 47px;
  }
}

.lds-ring-wrap {
  display: flex;
  justify-content: center;
  flex: 0 0 100%;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.click-disabled {
  pointer-events: none;
}

.is-full-width {
  width: 100%;
}
