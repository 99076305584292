@import './../../sass/reuse';

.toastContainer {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.69);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1100;
}

.dismissButton {
  color: $white;
  position: absolute;
  right: $gap / 4;
  top: $gap / 4;
  font-size: 28px;
  cursor: pointer;
}

.toast {
  color: #fff;
  font-size: 23px;
  font-weight: $weight-bold;
  background-color: $cyan;
  border-radius: 17px;
  text-align: center;
  max-width: 80vw;
  width: 100%;
  position: relative;
  padding: $gap;

  @include tablet {
    max-width: 566px;
  }

  @include mobile {
    font-size: 20px;
  }
}

.is-primary {
  background-color: $cyan;
}

.is-danger {
  background-color: #747474;
}

.is-error {
  background-color: #ff0033;
}
