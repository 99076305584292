@import '~swiper/swiper.scss';
$swiper-button-width: 132px;

.swiper-slide {
  > article {
    padding-top: 30px;

    @include mobile {
      padding-top: 0;
    }
  }
}

.swiper-button-next,
.swiper-button-prev {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  height: 100%;
  top: 0;
  width: $swiper-button-width / 2;

  @include mobile {
    display: none;
  }

  @include tablet {
    width: $swiper-button-width;
  }

  &.swiper-button-disabled {
    visibility: hidden;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    background:
      var(--arrow-right-svg) 90% 50% no-repeat,
      linear-gradient(to right, rgba(38, 38, 38, 0) 0%, $block-bg 100%);
  }
}

.swiper-button-prev {
  left: 0;

  &:before {
    left: 0;
    transform: scaleX(-1);
  }
}

.swiper-button-next {
  right: 0;

  &:before {
    right: 0;
  }
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 0.3s opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
  bottom: 50px;
  right: 50px;
}

.swiper-pagination-disabled {
  display: none;
}

.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 50%;
  background: $grey;
  margin: 0 6px;
  cursor: pointer;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: white;
}
