@import './../sass/reuse';
.loader {
  background: var(--wrapper-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1050;
}

.loader:after {
  position: absolute;
  content: ' ';
  width: 300px;
  height: 300px;
  border-radius: 50%;
  border: 4px solid var(--splash-screen-ring-color);
  border-top-color: $purple;
  animation: loading 2s linear infinite;

  @include mobile {
    width: 150px;
    height: 150px;
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
