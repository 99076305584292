@import './reuse';

@import './fonts';

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  text-transform: uppercase;
}

h1,
.h1 {
  font-size: rem(46px);
  font-weight: $weight-normal; //400
  margin-bottom: $gap / 2;

  @include tablet {
    margin-bottom: $gap;
    font-size: rem(56px);
  }

  @include mobile {
    font-size: 23px;
    margin-bottom: $gap / 3;
  }
}

h2,
.h2 {
  font-size: $size-6;
  letter-spacing: 1.8px;
  font-weight: $weight-bold; //700

  @include tablet {
    font-size: 17px;
    text-transform: uppercase;
    letter-spacing: 3.4px;
  }

  @include mobile {
    font-size: 12px;
  }
}

h3,
.h3 {
  @include h3;
}

h4,
.h4 {
  color: $purple;
}
